.container-web {
  width: 50%;
  margin: auto;
}
.container-web2 {
  padding: 0 50%;
}
body {
  /* background-color: red; */
  font-family: Poppins;
}

* {
  margin: 0;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.bg-primary {
  background-color: rgba(0, 138, 173, 1);
}

.w-full {
  width: 100%;
}

.ml-2 {
  margin-left: 2rem;
}

.w-90 {
  width: 90%;
}

.pt-1 {
  padding-top: 1rem;
}

.m-auto {
  margin: 0 auto;
}
.new-vendor-btn {
  height: 3rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: rgba(0, 204, 131, 1);
  margin: 2rem auto;
}
.icon-pic{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: black;
}
.icon-pic2{

  border-radius: 50%;
  background-color: black;
}
.mt-1 {
  margin-top: 1rem;
}
.align-center {
  align-items: center;
}
.flex {
  display: flex;
}

.h-20 {
  height: 20rem;
}

.h-4 {
  height: 4rem;
}

.border {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 0.4rem;
}

.text-grey {
  color: rgba(0, 0, 0, 0.6);
}

.text-input {
  width: 95%;
  margin: auto;
  height: 2rem;
  border: 1px solid rgba(232, 232, 232, 1);
  outline: none;
  padding-left: 1rem;
}

.form-label {
  color: rgba(0, 0, 0, 0.7);
}

/* .container-web {
  width: 100%;
  margin: auto;
} */

.container-web-login {
  width: 30%;

  margin: 12% auto;
}

.text-xm {
  font-size: 0.8rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pointer{
  cursor: pointer;
}
.space-2 {
  height: 2rem;
}
.ml-1 {
  margin-left: 1rem;
}
.mt-n1 {
  margin-top: 0rem;
}
.m-1 {
  margin: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.text-md {
  font-size: 1.8rem;
}
.header {
  height: 11rem;
  background-color: rgba(0, 138, 173, 1);
}

.font-md {
  font-size: 2rem;
}

.bg-grey {
  background-color: rgba(245, 245, 245, 1);
  border-radius: 0.6rem;
  height: 5rem;
}
.text-sm {
  font-size: 0.8rem;
}
.text-center {
  text-align: center;
}

.spacer {
  height: 20rem;
  display: flex;
  /* justify-content:end; */

  /* background-color: red; */
}
.flag-acc-btn {
  height: 3rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: #fd0b0b;
  margin: 2rem auto;
}

.icon-sm {
  font-size: 2rem;
}

.form-upload {
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(232, 232, 232, 1);
}
.h-8 {
  height: 8rem;
}
.h-3 {
  height: 5rem;
}
.text-lg {
  font-size: 1.7rem;
}
.green {
  color: rgba(0, 204, 131, 1);
}

.format-upload-btn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  height: 5rem;
  overflow: hidden;
  outline: none;
}
input[type="file"] {
  display: none;
}

.font-bold {
  font-weight: bold;
}

.profile-header {
  height: 20svh;
  background-color: #008AAD;
}
.profile-name{
  margin-left: 3rem;
  font-size: 1.4rem;
  font-weight: bold;
}

.dp-formatter{
 width: 10rem;
 height: 40%;
 /* background-color: red; */
 margin: auto;
}

.circlular-floating-btn {
  height: 3rem;
  cursor: pointer;
  width: 3rem;
  background-color: rgba(0, 204, 131, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
  .no-vendor{
    width: 100%;
    height:10rem;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }


@media all and (max-width: 480px) {
  body {
    /* background-color: red; */
    font-family: Poppins;
  }

  .container-web-login {
    width: 90%;

    margin: 50% auto;
  }



  * {
    margin: 0;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .bg-primary {
    background-color: rgba(0, 138, 173, 1);
  }

  .w-full {
    width: 100%;
  }

  .ml-2 {
    margin-left: 2rem;
  }

  .w-90 {
    width: 90%;
  }

  .pt-1 {
    padding-top: 1rem;
  }

  .m-auto {
    margin: 0 auto;
  }
  .new-vendor-btn {
    height: 3rem;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: rgba(0, 204, 131, 1);
    margin: 2rem auto;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .align-center {
    align-items: center;
  }
  .flex {
    display: flex;
  }

  .h-20 {
    height: 20rem;
  }

  .h-4 {
    height: 4rem;
  }

  .border {
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 0.4rem;
  }

  .text-grey {
    color: rgba(0, 0, 0, 0.6);
  }

  .text-input {
    width: 95%;
    margin: auto;
    height: 2rem;
    border: 1px solid rgba(232, 232, 232, 1);
    outline: none;
    padding-left: 1rem;
  }

  .bg-green {
    background-color: green;
    color: "#fff";
  }

  .form-label {
    color: rgba(0, 0, 0, 0.7);
  }

  .container-web {
    width: 100%;
    margin: auto;
  }

  .text-xm {
    font-size: 0.8rem;
  }
  .pl-2 {
    padding-left: 2rem;
  }
  .pl-1 {
    padding-left: 1rem;
  }
  .space-2 {
    height: 2rem;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .mt-n1 {
    margin-top: 0rem;
  }
  .m-1 {
    margin: 1rem;
  }


  .ml-2 {
    margin-left: 2rem;
  }
  .text-md {
    font-size: 1.8rem;
  }
  .header {
    height: 11rem;
    background-color: rgba(0, 138, 173, 1);
  }

  .font-md {
    font-size: 2rem;
  }

  .bg-grey {
    background-color: rgba(245, 245, 245, 1);
    border-radius: 0.6rem;
    height: 5rem;
  }
  .text-sm {
    font-size: 0.8rem;
  }
  .text-center {
    text-align: center;
  }

  .spacer {
    height: 20rem;
    display: flex;
    /* justify-content:end; */

    /* background-color: red; */
  }
  .flag-acc-btn {
    height: 3rem;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: #fd0b0b;
    margin: 2rem auto;
  }

  .icon-sm {
    font-size: 2rem;
  }

  .form-upload {
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid rgba(232, 232, 232, 1);
  }
  .h-8 {
    height: 8rem;
  }
  .h-3 {
    height: 5rem;
  }
  .text-lg {
    font-size: 1.7rem;
  }
  .green {
    color: rgba(0, 204, 131, 1);
  }

  .font-bold {
    font-weight: bold;
  }

  .circlular-floating-btn {
    height: 3rem;
    cursor: pointer;
    width: 3rem;
    background-color: rgba(0, 204, 131, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
